/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//font-family: 'IBM Plex Sans Hebrew', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;500;600;700&display=swap');

//font-family: 'Syne', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

      // 1st: SCSS variable overrides
$global-prefix : '';

$fieldBorderRadius: var(--border-radius-s);
$panelBorderRadius: var(--border-radius-s);
// 2nd: build import
@import '../../../../../../otherSrc/client/we/scss/build';

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks

@mixin hook-badge {
    &#{$badgePrefix}--bg-inverse {
        border-color: var(--c-bg);
        color: var(--c-bg);
        background-color: var(--c-inverse);
    }
}

@mixin hook-button {
    &--facebook {
        @include _button-color(var(--c-facebook), var(--c-bg), var(--c-bg), var(--c-facebook), true);
        border: 2px solid var(--c-facebook);
    }

    &__icon-start {
        margin-inline-end: var(--margin-m);
    }

    .button__icon-end {
        margin-inline-start: var(--margin-m);
    }

}

@mixin hook-form-field-placeholder {
    color: var(--c-gray-4);
    font-weight: 500;
    opacity: 1;
}
// 4th: component import
@import '../../../../../../otherSrc/client/we/scss/components';



// 5th: CSS variables declaration/override
:root {
    --c-core-lead-1           : #5749E4;
    --c-core-lead-2           : #483CB8;
    --c-core-lead             : #382F91;
    --c-core-lead-3           : #382F91;
    --c-core-lead-4           : #342B87;
    --c-core-lead-5           : #332C76;
    --c-core-alt-1            : #DA0033;
    --c-core-alt-2            : #C60230;
    --c-core-alt              : #C60230;
    --c-core-alt-4            : #B0012A;
    --c-core-alt-5            : #930022;

    --c-core-inverse          : #030211;
    --c-text                  : #000000;

    --c-core-bg-1             : #ffffff;
    --c-core-bg-2             : #ffffff;
    --c-core-bg               : #ffffff;
    --c-core-bg-4             : #F6F6F6;
    --c-core-bg-5             : #E4E4E4;

    --c-gray-1                : #EAEAEA;
    --c-gray-2                : #E0E0E0;
    --c-gray                  : #C7C7C7;
    --c-gray-3                : var(--c-gray);
    --c-gray-4                : #767676;
    --c-gray-5                : #5D5D5D;


    --c-danger                : #F10000;
    --c-danger-inverse        : var(--c-bg);
    --c-error                 : var(--c-danger);
    --c-error-inverse         : var(--c-danger-inverse);

    // color aliases
    --c-lead                  : var(--c-core-lead);
    --c-lead-1                : var(--c-core-lead-1);
    --c-lead-2                : var(--c-core-lead-2);
    --c-lead-4                : var(--c-core-lead-4);
    --c-lead-5                : var(--c-core-lead-5);
    --c-alt-1                 : var(--c-core-alt-1);
    --c-alt-2                 : var(--c-core-alt-2);
    --c-alt                   : var(--c-core-alt);
    --c-alt-4                 : var(--c-core-alt-4);
    --c-alt-5                 : var(--c-core-alt-5);
    --c-inverse               : var(--c-core-inverse);
    --c-bg-4                  : var(--c-core-bg-4);
    --c-bg-5                  : var(--c-core-bg-5);
    --c-bg                    : var(--c-core-bg);
    --c-bg-2                  : var(--c-core-bg-2);
    --c-bg-1                  : var(--c-core-bg-1);

    --margin-min: 1px;
    --margin-xs: 2px;
    --margin-s: 5px;
    --margin-m: 12px;
    --margin-l: 24px;
    --margin-xl: 38px;
    --margin-2xl: 42px;
    --margin-max: 76px;

    --border-radius-sharp     : calc(3 * var(--base-margin));
    --border-radius-s         : var(--border-radius-sharp);
    --border-radius           : calc(10 * var(--base-margin));
    --border-radius-m         : var(--border-radius);
    --border-radius-l         : calc(35 * var(--base-margin));

    /*
      --base-font-size          : 1px;

    --font-min                : calc(10 * var(--base-font-size));
    --font-xs                 : calc(12 * var(--base-font-size));
    --font-s                  : calc(14 * var(--base-font-size));
    --font-m                  : calc(16 * var(--base-font-size));
    --font-l                  : calc(18 * var(--base-font-size));
    --font-xl                 : calc(20 * var(--base-font-size));
    --font-2xl                : calc(25 * var(--base-font-size));
    --font-3xl                : calc(32 * var(--base-font-size));
    --font-max                : calc(38 * var(--base-font-size));

     */
}

// 6th: other custom CSS
*,
*::before,
*::after {
    box-sizing: border-box;

}
@property --lead-button-gradiant-1 {
    syntax: '<color>';
    initial-value: #C60230;
    inherits: false;
}

@property --lead-button-gradiant-2 {
    syntax: '<color>';
    initial-value: #382F91;
    inherits: false;
}
// 6th: custom css
html, body  {


    background: var(--c-bg);

    & * {
        font-family: 'Syne', sans-serif;
    }
    &[lang=he] * {
       // font-family: 'Rubik', 'Assistant', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
        font-family: 'IBM Plex Sans Hebrew', sans-serif;
       // border : 1px solid green;
    }


    *.syne {
        font-family: 'Syne', sans-serif;
        * {
            font-family: 'Syne', sans-serif;
        }
    }
    .button {
        border-radius: var(--margin-s);

        &--lead {
            color: var(--c-bg);
            border: none;
            background: var(--c-alt);
            background: linear-gradient(291deg, var(--lead-button-gradiant-1) 0%, var(--lead-button-gradiant-2) 100%);
            transition: --lead-button-gradiant-1 1s, --lead-button-gradiant-2 1s;

            // firefox fallback
            --lead-button-gradiant-1: #C60230;
            --lead-button-gradiant-2: #382F91;

            &:hover {
                color: var(--c-bg);
                --lead-button-gradiant-1: #382F91;
                --lead-button-gradiant-2: #C60230;
            }
        }
    }

    .major-title {
        font-size: calc(3 * var(--font-max));
        font-weight: 300;
        margin-bottom: calc(var(--margin-m) * 1.2);

        @media (max-width: 1024px) {
            font-size: calc(2 * var(--font-max));
            margin-bottom: var(--margin-m);
        }
    }

    .minor-title {
        font-size: calc(2 * var(--font-max));
        font-weight: 700;
        margin-bottom: var(--margin-xl);

        @media (max-width: 1024px) {
            font-size: var(--font-max);
            margin-bottom: var(--margin-l);
        }
    }

    .mini-title {
        font-size:  var(--font-max);
        font-weight: 700;
        margin-bottom: var(--margin-xl);

        @media (max-width: 1024px) {
            font-size: var(--font-2xl);
            margin-bottom: var(--margin-l);
        }
    }

    .major-text {
        font-size: var(--font-2xl);
        line-height: 1.3;

        @media (max-width: 1024px) {
            font-size: var(--font-m);
        }
    }

    .gradiant-text {
        background: radial-gradient(68.7% 98.7% at 20.1% 40.86%, var(--c-lead) 0%, #B451FF 39.58%, var(--c-alt) 89.06%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
